import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { ServiceType } from '../interface/service-type';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceTypeService {

 private _isFromMatrix = true;


  constructor(
    private _http: HttpClient,
    private _dataService: DataService
  ) { }

  public async getAll(cnpj?: string): Promise<ServiceType[]> {
    const url = `${environment.mkgoURL}/api/v1/service-type/`;
    const header = await firstValueFrom(this._dataService.httpOptions(cnpj || this._isFromMatrix));
    const resp: any = await this._http.get(url, header).pipe(first()).toPromise();
    return (resp['groups'] as ServiceType[]).reverse();
  }

  public async getById(id: string) {
    const url = `${environment.mkgoURL}/api/v1/service-type/${id}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp = await this._http.get(url, header).pipe(first()).toPromise();
    return resp;
  }

  public async update(serviceType: ServiceType) {
    const url = `${environment.mkgoURL}/api/v1/service-type/${serviceType._id}`;
    const apiServiceType = this.complyApi(serviceType);
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp = await this._http.put(url, JSON.stringify(apiServiceType), header).pipe(first()).toPromise();
    return resp;
  }

  public async register(serviceType: ServiceType): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/service-type/`;
    const apiServiceType = this.complyApi(serviceType);
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this._http.post(url, JSON.stringify(apiServiceType), header).pipe(first()).toPromise();
    return resp['id'];
  }

  public async delete(id: string) {
    const url = `${environment.mkgoURL}/api/v1/service-type/${id}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp = await this._http.delete(url, header).pipe(first()).toPromise();
    return resp;
  }

  private complyApi(serviceType: ServiceType) {
    return {
      description: serviceType.description
    }
  }
}

